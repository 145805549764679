import { EvsCallHistory } from '../EvsCallHistory';
import { DataTable } from '../../features/DataTable';
import { ToolTipEvsCall } from '../toolTip/ToolTipEvsCall';
import React from 'react';
import { DetailedDataTable } from '../../features/DetailedDataTable';

export interface EvsCallTableProps {
  evsCallHistory: EvsCallHistory;
  tn: string;
  hasError?: boolean;
  detailedTable?: boolean;
}

export const EvsCallTable: React.FC<EvsCallTableProps> = ({
  evsCallHistory,
  tn,
  hasError,
  detailedTable,
}) => {
  const c: string[][] = [[]];

  evsCallHistory?.calls?.forEach((i, idx) => {
    c[idx] = [];
    c[idx].push(i.callback);
    c[idx].push(i.account);
    c[idx].push(i.name); // name
    c[idx].push(i.created);
    c[idx].push(i.callerId);
    c[idx].push(i.location?.addr1);
    c[idx].push(i.location?.loc);
    c[idx].push(i.location?.a3);
    c[idx].push(i.location?.a1);
    c[idx].push(i.location?.pc);
    c[idx].push(i.location?.country);
    c[idx].push(i.location?.lat);
    c[idx].push(i.location?.lon);
  });

  if (detailedTable) {
    return (
      <DetailedDataTable
        cs={EvsCallColumns}
        data={c}
        title={TableNameEvsCall}
        tn={tn}
      />
    );
  }

  return (
    <DataTable
      cs={EvsCallColumns}
      data={c}
      title={TableNameEvsCall}
      limit={10}
      urlTitle={TableUrlNameEvsCall}
      tn={tn}
      toolTip={<ToolTipEvsCall />}
      serviceName="EVS Call API"
      hasError={hasError}
    />
  );
};

export const EvsCallColumns = [
  'Number',
  'Account',
  'Caller Name',
  'Created Time',
  'Identifier',
  'Address 1',
  'Address 2',
  'City',
  'State',
  'Zip',
  'Country',
  'Latitude',
  'Longitude',
];

export const TableNameEvsCall = 'EVS Call History';

export const TableUrlNameEvsCall = 'evsCall';
