import React from 'react';
import { Box, Chip, colors } from '@bw/foundry';

export interface ChipProps {
  serviceTypes?: string[];
}

export const NhaChip: React.FC<ChipProps> = ({ serviceTypes }) => {
  let r;
  if (serviceTypes) {
    r = serviceTypes.map((j) => (
      <div key={j} style={{ paddingLeft: 4 }}>
        {j === 'Voice' && (
          <Chip
            label="Voice"
            style={{ backgroundColor: colors.purple[500], color: colors.white }}
          />
        )}
        {j === 'Messaging' && (
          <Chip
            label="Messaging"
            style={{
              backgroundColor: colors.green[500],
              color: colors.green.A700,
            }}
          />
        )}
        {j === 'E911' && (
          <Chip
            label="E911"
            style={{ backgroundColor: colors.orange[500], color: colors.white }}
          />
        )}
        {j === 'Hosted Messaging' && (
          <Chip
            label="Hosted Messaging"
            style={{
              backgroundColor: colors.green[500],
              color: colors.green.A700,
            }}
          />
        )}
        {j === 'Hosted E911' && (
          <Chip
            label="Hosted E911"
            style={{ backgroundColor: colors.orange[500], color: colors.white }}
          />
        )}
      </div>
    ));
  }
  return (
    <Box display="flex" flexDirection="row">
      {r}
    </Box>
  );
};
