import React, { Suspense, useEffect, useState } from 'react';
import { ThemeProvider, CssBaseline, Loader } from '@bw/foundry';
import { BrowserRouter } from 'react-router-dom';
import Navigation from './features/Navigation';
import Router from './Routes';
import ApiProvider from './providers/ApiProvider';
import { oktaAuth, OktaAuthSecurity } from './OktaAuthSecurity';
import ThirdParty from './ThirdParty';
import { datadogRum } from '@datadog/browser-rum';
import datadogConfig from './config/datadogConfig';
import { datadogLogs } from '@datadog/browser-logs';
import { matchesEnvironment } from './utils/matchEnvironment';
import { atom, RecoilRoot } from 'recoil';
import { PostNumberHistoryReportResponse } from './types/PostNumberHistoryReportResponse';
import { SnapbackDetailsResponse } from './types/SnapbackDetailsResponse';

datadogRum.init({
  ...datadogConfig,
  silentMultipleInit: true,
  trackUserInteractions: true,
});

datadogLogs.init({
  ...datadogConfig,
  silentMultipleInit: true,
  forwardErrorsToLogs: !matchesEnvironment('local'),
});

export const inputAtom = atom({
  key: 'input',
  default: [''],
});

export const tnsAtom = atom({
  key: 'tns',
  default: '',
});

export const responseAtom = atom<PostNumberHistoryReportResponse>({
  key: 'response',
  default: { data: [], errors: [] },
});

export const snapbackAtom = atom<SnapbackDetailsResponse>({
  key: 'snapbackResponse',
  default: { data: { snapbacks: [], totalCount: 0 } },
});

/** Top level application component. */
const App: React.FC = () => {
  const [isSnapbackUser, setIsSnapbackUser] = useState(false);

  const checkSnapbackPermissions = async () => {
    const tokens = await oktaAuth.token.getWithoutPrompt({
      responseType: ['id_token', 'token'],
    });
    const userAuth =
      Array.isArray(tokens.tokens.idToken?.claims.roles) &&
      tokens.tokens.idToken?.claims.roles.includes('Snapback Dashboard');

    setIsSnapbackUser(userAuth === true);
  };

  useEffect(() => {
    checkSnapbackPermissions();
  }, []);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL || '/'}>
      <ApiProvider>
        <CssBaseline>
          <ThemeProvider>
            <Suspense fallback={<Loader />}>
              <OktaAuthSecurity>
                <ThirdParty />
                <RecoilRoot>
                  <Navigation isSnapbackUser={isSnapbackUser} />
                  <Router isSnapbackUser={isSnapbackUser} />
                </RecoilRoot>
              </OktaAuthSecurity>
            </Suspense>
          </ThemeProvider>
        </CssBaseline>
      </ApiProvider>
    </BrowserRouter>
  );
};

export default App;
