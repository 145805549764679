import React from 'react';
import { ToolTipTable } from '../../features/ToolTipTable';

export const ToolTipIris: React.FC = () => {
  const status = () => {
    return (
      <>
        <ul>
          <li>Available - Available for ordering</li>
          <li>Inservice - In service with one of our customers</li>
          <li>Disconnected - Not in service - used for testing</li>
          <li>Aging - Not in service, but not yet available for ordering</li>
          <li>
            PortInPendingFoc - Not owned by Iris, awaiting completion of a
            port-in action
          </li>
          <li>
            Virtual - Available for ordering, but part of a reserved inventory
            pool
          </li>
          <li>
            Reserved - In the middle of an ordering process - not available for
            ordering, but not yet assigned to an account
          </li>
        </ul>
      </>
    );
  };
  const tooltip: any[][] = [
    ['Number', 'The Iris phone number'],
    ['Status', status()],
    ['Last Modified', 'Last date the record was modified'],
    ['AccountId', 'The account id the telephone number is associated with'],
    ['AccountName', 'The account name the telephone number is associated with'],
    ['GAN', 'GAN'],
  ];

  return <ToolTipTable data={tooltip} />;
};
