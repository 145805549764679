import { EvsProvisionHistory } from '../EvsProvisionHistory';
import { DataTable } from '../../features/DataTable';
import { ToolTipEvsProv } from '../toolTip/ToolTipEvsProv';
import React from 'react';
import { DetailedDataTable } from '../../features/DetailedDataTable';

export interface EvsProvisionTableProps {
  evsProvisionHistory: EvsProvisionHistory;
  tn: string;
  hasError?: boolean;
  detailedTable?: boolean;
}
export const EvsProvisionTable: React.FC<EvsProvisionTableProps> = ({
  evsProvisionHistory,
  tn,
  hasError,
  detailedTable,
}) => {
  const c: string[][] = [[]];

  evsProvisionHistory?.events?.forEach((i, idx) => {
    c[idx] = [];
    c[idx].push(i.endpoint?.callback);
    c[idx].push(i.endpoint?.accountName);
    c[idx].push(i.endpoint?.name);
    c[idx].push(i.endpoint?.id);
    c[idx].push(i.location?.addr1);
    c[idx].push(i.location?.loc);
    c[idx].push(i.location?.a3);
    c[idx].push(i.location?.a1);
    c[idx].push(i.location?.pc);
    c[idx].push(i.location?.country);
    c[idx].push(i.endpoint?.created);
    c[idx].push(i.endpoint?.cancelled);
    c[idx].push(i.location?.activated);
    c[idx].push(i.location?.deactivated);
    c[idx].push(i.eventType);
  });

  if (detailedTable) {
    return (
      <DetailedDataTable
        cs={EvsProvColumns}
        data={c}
        title={TableNameEvsProv}
        tn={tn}
      />
    );
  }

  return (
    <DataTable
      cs={EvsProvColumns}
      data={c}
      title={TableNameEvsProv}
      limit={10}
      urlTitle={TableUrlNameEvsProv}
      tn={tn}
      toolTip={<ToolTipEvsProv />}
      serviceName="EVS Provision API"
      hasError={hasError}
    />
  );
};

export const EvsProvColumns = [
  'Number',
  'Account Name',
  'Caller Name',
  'Identifier',
  'Address 1',
  'Address 2',
  'City',
  'State',
  'Zip',
  'Country',
  'Endpoint Create Time',
  'Endpoint Delete Time',
  'Address Activation Time',
  'Address Deactivation Time',
  'Event Type',
];

export const TableNameEvsProv = 'EVS Provisioning History';

export const TableUrlNameEvsProv = 'evsProv';
