import React from 'react';
import { Paper, Table, TableBody, TableCell, TableRow } from '@bw/foundry';

export interface ToolTipProps {
  data: any[][];
}

export const ToolTipTable: React.FC<ToolTipProps> = ({ data }) => {
  return (
    <Paper>
      <Table
        size="medium"
        style={{ borderBottom: 'none', borderCollapse: 'separate' }}
      >
        <TableBody>
          {data.map((row) => (
            <TableRow key={Math.random()}>
              <TableCell key={row[0]} width="2%">
                <b>{row[0]}</b>
              </TableCell>
              <TableCell key={row[1]} width="98%">
                {row[1]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};
