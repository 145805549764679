import React, { lazy } from 'react';
import { Route, Routes } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';
import NumberHistoryDetail from './pages/NumberHistoryDetail';
import { oktaAuth } from 'OktaAuthSecurity';

export interface RoutesProps {
  isSnapbackUser?: boolean;
}

// Lazy imports allow you to use React suspense.
const NumbersHistory = lazy(() => import('./pages/NumbersHistory'));
const SnapbackDetails = lazy(() => import('./pages/SnapbackDetails'));

/**
 * Top-level routing using React router.
 */
const Router: React.FC<RoutesProps> = ({ isSnapbackUser }) => {
  const { authState, _onAuthRequired } = useOktaAuth();

  if (!authState || !authState.isAuthenticated) {
    if (_onAuthRequired) _onAuthRequired(oktaAuth);
    return <></>;
  }

  // Only allow the user to route to the snapback page if they have permission
  if (isSnapbackUser) {
    return (
      <Routes>
        <Route path="/" element={<NumbersHistory />} />
        <Route path="/details/:table/:tn" element={<NumberHistoryDetail />} />
        <Route path="/snapback-details" element={<SnapbackDetails />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<NumbersHistory />} />
      <Route path="/details/:table/:tn" element={<NumberHistoryDetail />} />
    </Routes>
  );
};

export default Router;
