import React from 'react';
import { ToolTipTable } from '../../features/ToolTipTable';

export const ToolTipIrisOrder: React.FC = () => {
  const status = () => {
    return (
      <>
        The TN status at the time of the order:
        <ul>
          <li>
            Inservice: The TN is currently in use by the customer that owns the
            TN.
          </li>
          <li>
            Available: The TN is available to be ordered. If the TN is
            &quot;protected&quot; only the account owning the TN can order it,
            otherwise it should be generally available.
          </li>
          <li>
            Aging: The TN is disconnected by the customer owning the TN. At this
            time,&#10; the TN will go into aging for some period of time
            (typically 30 days) and the&#10; customer owning the TN can order it
            again. If the allotted time has passed and the TN&#10; is not
            ordered by the owning customer, the TN will go back to Available.
          </li>
          <li>PortInPendingFoc: TN is in FOC and waiting for the FOC date.</li>
          <li>PortOut: A TN has been ported out of an account.</li>
          <li>
            Reserved: A TN is reserved by a customer to order at a later time.
          </li>
          <li>
            Removed: Bandwidth will show this for TNs that have been
            soft-removed.
          </li>
          <li>
            Eliminated: This status will shown for external TNs that are removed
            from Bandwidth.
          </li>
        </ul>
      </>
    );
  };

  const orderStatus = () => {
    return (
      <>
        Order type the customer initiated on this TN.
        <ul>
          <li>
            EXTERNAL_TNS: Activates or removes hosted messaging service on
            external TNs
          </li>
          <li>
            IMPORT_TN_ORDERS: Activates hosted messaging service on external or
            Inservice Bandwidth TNs
          </li>
          <li>
            REMOVE_IMPORTED_TN_ORDERS: Removes hosted messaging service on
            external or Inservice Bandwidth TNs. Please note hosted messaging
            service shall be removed on Bandwidth TN if the numbers gets ported
            or disconnected too.
          </li>
        </ul>
      </>
    );
  };
  const tooltip: any[][] = [
    ['Number', 'The Iris phone number'],
    ['Account Id', 'The Iris account id associated to the telephone number'],
    ['Account Name', 'The name of the account, derived based on the ID'],
    ['Last Modified', 'The account id the telephone number is associated with'],
    ['Order Id', 'The account name the telephone number is associated with'],
    ['Status', status()],
    ['User Id', 'The user id that changed this record'],
    ['Order Type', orderStatus()],
    ['Order Status', 'Status of the order for the telephone number'],
  ];

  return <ToolTipTable data={tooltip} />;
};
