import { IrisTnDetails } from '../IrisTnDetails';
import { NhaChip } from '../../features/NhaChip';
import { Box } from '@bw/foundry';
import React from 'react';
import { DataTable } from '../../features/DataTable';
import { ToolTipIris } from '../toolTip/ToolTipIris';
import { DetailedDataTable } from '../../features/DetailedDataTable';

export interface IrisStatusColumnProps {
  irisTnDetails: IrisTnDetails[];
  tn: string;
  hasError?: boolean;
  detailedTable?: boolean;
}

export const IrisStatusColumn: React.FC<IrisStatusColumnProps> = ({
  irisTnDetails,
  tn,
  hasError,
  detailedTable,
}) => {
  const c: any[][] = [[]];
  irisTnDetails?.forEach((i, idx) => {
    c[idx] = [];
    c[idx].push(
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
        }}
      >
        {tn} <NhaChip serviceTypes={i.serviceTypes} />
      </Box>,
    );
    c[idx].push(i?.tnStatus);
    c[idx].push(i?.accountId);
    c[idx].push(i?.companyName);
    c[idx].push(i?.customerSegment);
    c[idx].push(i?.globalAccountNumber);
    c[idx].push(i?.serviceTypes?.join(', '));
  });

  if (detailedTable) {
    return (
      <DetailedDataTable
        cs={IrisStatusColumns}
        data={c}
        title={TableNameIrisStatus}
        tn={tn}
      />
    );
  }

  return (
    <DataTable
      cs={IrisStatusColumns}
      data={c}
      title={TableNameIrisStatus}
      limit={10}
      urlTitle={TableUrlNameIris}
      tn={tn}
      toolTip={<ToolTipIris />}
      serviceName="Iris Details API"
      hasError={hasError}
    />
  );
};
const IrisStatusColumns = [
  'Number',
  'Status',
  'AccountId',
  'AccountName',
  'Customer Segment',
  'GAN',
  'Service Type',
];

export const TableNameIrisStatus = 'Current Iris Status';

const TableUrlNameIris = 'iris';
