import React from 'react';
import { ToolTipTable } from '../../features/ToolTipTable';

export const ToolTipEvsCall: React.FC = () => {
  const tooltip: any[][] = [
    ['Number', 'The caller number to 911'],
    ['Account Name', 'CRM ID of account that made the call'],
    ['Caller Name', 'The name of call making 911 call'],
    ['Identifier', 'String that identifies the caller. TN, ACID, or SIP URI.'],
    ['Address 1', 'Address 1 of service address used for the call'],
    ['Address 2', 'Address 2 of service address used for the call'],
    ['City', 'City of service address used for the call'],
    ['State', 'State of service address used for the call'],
    ['Zip', 'Zip code of service address used for the call'],
    ['Country', 'Country of service address used for the call'],
    ['Latitude', 'Latitude of caller in decimal degrees'],
    ['Longitude', 'Longitude of caller in decimal degrees'],
  ];

  return <ToolTipTable data={tooltip} />;
};
