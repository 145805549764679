/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';

// eslint-disable-next-line no-shadow
export enum LogEvent {
  ClickLink = 'Clicked Link',
  SubmitForm = 'Submitted Form',
  APIResponse = 'Returned API',
}

// eslint-disable-next-line no-shadow
export enum LogEventStatus {
  Successful = 'successful',
  Info = 'info',
  HandledError = 'handled error',
  UnhandledError = 'unhandled error',
}

/**
 * Log an event in Datadog Logs. Useful for when you
 * want to track specific actions/events in the app.
 *
 * @param event event name
 * @param status event status
 * @param data custom attributes to attach to the event
 */
export const logEvent = (
  event: LogEvent,
  status: LogEventStatus,
  data?: Record<string, any>,
): void => {
  datadogLogs.logger.log(
    `${event} ${status}`,
    {
      ...data,
      evt: {
        name: event,
        outcome: status,
      },
    },
    status === LogEventStatus.UnhandledError ||
      status === LogEventStatus.HandledError
      ? 'error'
      : 'info',
  );
};
