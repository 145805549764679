import React from 'react';
import { ToolTipTable } from '../../features/ToolTipTable';

export const ToolTipEvsProv: React.FC = () => {
  const tooltip: any[][] = [
    [
      'Number',
      'String that uniquely identifies the caller for this account.  TN, ACID/AEUI, or SIP URI.  Required',
    ],
    ['Account Name', 'Name of the account that owns the endpoint'],
    ['Caller Name', 'Name of the caller or business making 911 call'],
    ['Identifier', 'Caller number'],
    ['Address 1', 'Address 1 of the service address used for the call'],
    ['Address 2', 'Address 2 of the service address used for the call'],
    ['City', 'City of the service address used for the call'],
    ['State', 'State of the service address used for the call'],
    ['Zip', 'Zip code of the service address used for the call'],
    ['Country', 'Country of the service address used for the call'],
    ['Endpoint Create Time', 'Timestamp when the endpoint was created'],
    ['Endpoint Delete Time', 'Timestamp when the endpoint was cancelled'],
    [
      'Address Activation Time',
      'Timestamp when the location was last activated, may be empty if location was not activated',
    ],
    [
      'Address Deactivation Time',
      'Timestamp when the location was disassociated from the endpoint',
    ],
    ['Event Type', 'Type of event'],
  ];

  return <ToolTipTable data={tooltip} />;
};
