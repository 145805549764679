import React from 'react';
import { NavButton } from '@bw/foundry';
import { useOktaAuth } from '@okta/okta-react';
import urlcat from 'urlcat';
import oktaConfig from '../config/oktaConfig';
import { datadogRum } from '@datadog/browser-rum';

/**
 * Logout button for the navigation.
 */

const LogoutLink: React.FC<{
  variant?: 'vertical' | 'default';
  size?: 'default' | 'small';
}> = (props) => {
  const { oktaAuth } = useOktaAuth();
  datadogRum.clearUser();
  return (
    <NavButton
      data-bw="nav-logout"
      {...props}
      onClick={async () => {
        localStorage.setItem('results', '');
        localStorage.setItem('tns', '');
        localStorage.setItem('input', '');
        const originalUri = oktaAuth.getOriginalUri();
        await oktaAuth.revokeAccessToken();
        await oktaAuth.revokeRefreshToken();
        await oktaAuth.closeSession();
        window.location.assign(
          urlcat(oktaConfig.url, 'login', {
            returnTo: originalUri,
          }),
        );
      }}
    >
      Log Out
    </NavButton>
  );
};

export default LogoutLink;
