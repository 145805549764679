import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@bw/foundry';

export interface DetailedDataTableProps {
  cs?: string[];
  data: string[][];
  title?: string;
  tn?: string;
}

export const DetailedDataTable: React.FC<DetailedDataTableProps> = ({
  data,
  cs,
  title,
  tn,
}) => {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 100;
  // @ts-ignore
  const end =
    (page + 1) * rowsPerPage > data?.length
      ? data?.length
      : (page + 1) * rowsPerPage;

  const start = page * rowsPerPage + 1;
  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h2" gutterBottom>
          {title} for {tn}
        </Typography>
        <Typography variant="caption" gutterBottom>
          {start} - {end} of {data.length}
        </Typography>
        <Table size="medium">
          <TableHead>
            <TableRow>
              {cs?.map((c) => (
                <TableCell style={{ fontWeight: 'bold' }} key={c}>
                  {c}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={Math.random()}>
                  <TableCell key={Math.random()}>{row[0]}</TableCell>
                  {row.slice(1).map((r) => (
                    <TableCell key={r}>{r}</TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TableFooter>
          <TablePagination
            page={page}
            count={data ? data.length : rowsPerPage}
            rowsPerPage={rowsPerPage}
            onPageChange={(ev, newPage) => setPage(newPage)}
          />
        </TableFooter>
      </Box>
    </Paper>
  );
};
