import React, { PropsWithChildren } from 'react';
import { JanusHookshotProvider, SwrOptions } from '@bw/hookshot';
import urlcat from 'urlcat';
import serviceConfig from '../config/serviceConfig';
import { oktaAuth } from '../OktaAuthSecurity';

const swrOptions: SwrOptions = {
  shouldRetryOnError: false,
  revalidateOnFocus: false,
  loadingTimeout: 60,
};

const customFetch = async (
  input: RequestInfo,
  init: RequestInit = { mode: 'no-cors' },
) => {
  const url = serviceConfig;
  const request =
    typeof input === 'string'
      ? urlcat(url, input)
      : {
          ...input,
          url: urlcat(url, input.url),
        };

  const accessToken = oktaAuth.getAccessToken();
  let response: Response;
  await window
    .fetch(request, {
      ...init,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        ...init.headers,
      },
    })
    .then((res) => {
      response = res;
    })
    .catch(() => {
      response = new Response();
    });

  // @ts-ignore
  return response;
};

/** Provides configured hookshot settings to passport. */
const ApiProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <JanusHookshotProvider
      customFetch={customFetch}
      swrOptions={swrOptions}
      defaultFormat="json"
    >
      {children}
    </JanusHookshotProvider>
  );
};

export default ApiProvider;
