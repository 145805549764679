import React, { useEffect, useState } from 'react';
import useNumberHistoryReports from '../hooks/useNumberHistoryReports';
import { Divider, Loader, Paper, Typography } from '@bw/foundry';
import { useParams } from 'react-router';
import {
  CatapultColumn,
  TableNameCatapult,
} from '../types/table/CatapultColumn';
import {
  IrisOrderHistoryColumn,
  TableNameIrisOrder,
} from '../types/table/IrisOrderHistoryColumn';
import { MyBwcColumn, TableNameMyBwc } from '../types/table/MyBwcColumn';
import {
  EvsProvisionTable,
  TableNameEvsProv,
} from '../types/table/EvsProvisionTable';
import { EvsCallTable, TableNameEvsCall } from '../types/table/EvsCallTable';
import { PostNumberHistoryReportResponse } from '../types/PostNumberHistoryReportResponse';
import { LogEvent, logEvent, LogEventStatus } from '../utils/logEvent';
import { Link } from 'react-router-dom';
import {
  IrisStatusColumn,
  TableNameIrisStatus,
} from '../types/table/IrisStatusColumn';

const phoneFormatter = require('phone-formatter');

const NumberHistoryDetail: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const postNumberHistoryReport = useNumberHistoryReports(true);
  const [title, setTitle] = useState('');
  const [tableElement, setTableElement] = useState<JSX.Element>();
  const regex = new RegExp(
    '^([0-9]{1,3})?\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$',
  ); // US number regex
  const uSE164Format = '+1NNNNNNNNNN';
  // @ts-ignore
  const { table } = useParams();
  // @ts-ignore
  const { tn } = useParams();

  let temp =
    tn
      ?.replace(/[\s,]+$/g, '') // Remove spaces and commas at the end of the string
      .replace(/^[\s,]+/g, '') // remove spaces and commas at the beginning of the string
      .replace(/ /g, '') ?? ''; // replace all space

  if (regex.test(temp)) {
    temp = phoneFormatter.format(temp, uSE164Format); // Convert to E164 US
  }

  useEffect(() => {
    const fetch = async () => {
      let response: PostNumberHistoryReportResponse;
      // @ts-ignore
      if (!response) {
        // @ts-ignore
        ({ data: response } = await postNumberHistoryReport({
          input: { phoneNumbers: [temp] },
        }));
      }
      setLoading(false);
      if (response?.data[0]) {
        const [resp] = response.data;
        logEvent(LogEvent.ClickLink, LogEventStatus.Info, {
          msg: 'Clicked Numbers Detail',
          tn,
          table,
        });
        logEvent(LogEvent.APIResponse, LogEventStatus.Successful, {
          response: resp,
        });

        if (table === 'catapult') {
          setTableElement(
            <CatapultColumn
              catapultHistory={resp.catapultNumberHistory}
              tn={resp.tn}
              detailedTable
            />,
          );
          setTitle(TableNameCatapult);
        } else if (table === 'iris') {
          setTableElement(
            <IrisStatusColumn
              irisTnDetails={resp.irisTnDetail}
              tn={resp.tn}
              detailedTable
            />,
          );
          setTitle(TableNameIrisStatus);
        } else if (table === 'irisOrder') {
          setTableElement(
            <IrisOrderHistoryColumn
              irisTnHistory={resp.irisTnHistory}
              tn={resp.tn}
              detailedTable
            />,
          );
          setTitle(TableNameIrisOrder);
        } else if (table === 'myBwc') {
          setTableElement(
            <MyBwcColumn
              myBwcDetails={resp.myBwcTnHistory}
              tn={resp.tn}
              detailedTable
            />,
          );
          setTitle(TableNameMyBwc);
        } else if (table === 'evsCall') {
          setTableElement(
            <EvsCallTable
              evsCallHistory={resp.evsCallHistory}
              tn={resp.tn}
              detailedTable
            />,
          );
          setTitle(TableNameEvsCall);
        } else if (table === 'evsProv') {
          setTableElement(
            <EvsProvisionTable
              evsProvisionHistory={resp.evsProvisionHistory}
              tn={resp.tn}
              detailedTable
            />,
          );
          setTitle(TableNameEvsProv);
        }
      }
    };
    fetch();
  }, [postNumberHistoryReport, table, temp, tn]);

  return (
    <Paper>
      <div style={{ padding: 20 }}>
        <Link to="/">Numbers History Report </Link>

        <Typography variant="button" gutterBottom style={{ padding: 5 }}>
          {'>'} {title}
        </Typography>
      </div>
      <Divider gutterBottom light />
      {!loading && tableElement}
      {loading && <Loader />}
    </Paper>
  );
};

export default NumberHistoryDetail;
