import { IrisTnHistory } from '../IrisTnHistory';
import { DataTable } from '../../features/DataTable';
import { ToolTipIrisOrder } from '../toolTip/ToolTipIrisOrder';
import React from 'react';
import { DetailedDataTable } from '../../features/DetailedDataTable';
import { Box } from '@bw/foundry';
import { NhaChip } from '../../features/NhaChip';

export interface IrisOrderHistoryColumnProp {
  irisTnHistory: IrisTnHistory[];
  tn: string;
  hasError?: boolean;
  detailedTable?: boolean;
}
export const IrisOrderHistoryColumn: React.FC<IrisOrderHistoryColumnProp> = ({
  irisTnHistory,
  tn,
  hasError,
  detailedTable,
}) => {
  const c: any[][] = [[]];

  if (irisTnHistory) {
    irisTnHistory.forEach((i, idx) => {
      c[idx] = [];
      c[idx].push(tn);
      c[idx].push(i.accountId);
      c[idx].push(i.accountName);
      c[idx].push(i.gan);
      c[idx].push(checkStatusForMessage(i.orderType));
      c[idx].push(i.orderStatus);
      c[idx].push(i.lastModifiedDate);
      c[idx].push(i.status);
      c[idx].push(i.userId);
      c[idx].push(i.orderId);
    });
  }

  if (detailedTable) {
    return (
      <DetailedDataTable
        cs={IrisOrderColumn}
        data={c}
        title={TableNameIrisOrder}
        tn={tn}
      />
    );
  }
  return (
    <DataTable
      cs={IrisOrderColumn}
      data={c}
      title={TableNameIrisOrder}
      limit={10}
      urlTitle={TableUrlNameIrisOrder}
      tn={tn}
      toolTip={<ToolTipIrisOrder />}
      serviceName="Iris Order History API"
      hasError={hasError}
    />
  );
};

const checkStatusForMessage = (orderType: string) => {
  if (
    orderType === 'EXTERNAL_TNS_ORDER' ||
    orderType === 'IMPORT_TN_ORDERS' ||
    orderType === 'REMOVE_IMPORTED_TN_ORDERS'
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
        }}
      >
        {orderType}
        <div style={{ paddingLeft: 4 }}>
          <NhaChip serviceTypes={['Messaging']} />
        </div>
      </Box>
    );
  }
  return <> {orderType}</>;
};

export const IrisOrderColumn = [
  'Number',
  'Account Id',
  'Account Name',
  'GAN',
  'Order Type',
  'Order Status',
  'Last Modified',
  'Status',
  'User Id',
  'Order Id',
];

export const TableNameIrisOrder = 'Iris Order History';

export const TableUrlNameIrisOrder = 'irisOrder';
