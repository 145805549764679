import { MyBwcDetails } from '../MyBwcDetails';
import { DataTable } from '../../features/DataTable';
import { ToolTipMyBwc } from '../toolTip/ToolTipMyBwc';
import React from 'react';
import { DetailedDataTable } from '../../features/DetailedDataTable';

export interface MyBwcColumnProp {
  myBwcDetails: MyBwcDetails[];
  tn: string;
  hasError?: boolean;
  detailedTable?: boolean;
}
export const MyBwcColumn: React.FC<MyBwcColumnProp> = ({
  myBwcDetails,
  tn,
  hasError,
  detailedTable,
}) => {
  const c: string[][] = [[]];

  myBwcDetails?.forEach((i, idx) => {
    c[idx] = [];
    c[idx].push(i.fullNumber);
    c[idx].push(i.tnType);
    c[idx].push(i.accountName);
    c[idx].push(i.status);
    c[idx].push(i.historyDateCreated);
    c[idx].push(i.primaryEmail);
    c[idx].push(i.name);
    c[idx].push(JSON.stringify(i.obsolete));
    c[idx].push(i.gatewayName);
    c[idx].push(i.vendorName);
  });

  if (detailedTable) {
    return (
      <DetailedDataTable
        cs={MyBwcColumns}
        data={c}
        title={TableNameMyBwc}
        tn={tn}
      />
    );
  }
  return (
    <DataTable
      cs={MyBwcColumns}
      data={c}
      title={TableNameMyBwc}
      limit={10}
      urlTitle={TableUrlNameMyBwc}
      tn={tn}
      toolTip={<ToolTipMyBwc />}
      serviceName={TableServiceNameMyBwc}
      hasError={hasError}
    />
  );
};

export const MyBwcColumns = [
  'Full Number',
  'TN Type',
  'Account Name',
  'Status',
  'History Date Created (EDT/EST)',
  'Primary Email',
  'Name',
  'Obsolete',
  'Gateway Name',
  'Vendor Name',
];

export const TableNameMyBwc = 'My BWC';

export const TableUrlNameMyBwc = 'myBwc';

export const TableServiceNameMyBwc = 'My-BWC';
