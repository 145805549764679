import React from 'react';
import { ToolTipTable } from '../../features/ToolTipTable';

export const ToolTipCatapult: React.FC = () => {
  const numberState = () => {
    return (
      <>
        <ul>
          <li>
            InService - Indicates the number is currently in service with
            Catapult
          </li>
          <li>Released - Indicates the number is no longer in Catapult</li>
        </ul>
      </>
    );
  };

  const tooltip: any[][] = [
    ['Number', 'The Catapult telephone phone number'],
    ['Number Id', 'The unique ID for the telephone number in Catapult'],
    ['First Name', "The account owner's first name"],
    ['Last Name', "The account owner's last name"],
    ['Company Name', "The account owner's company name"],
    ['Number State', numberState()],
    [
      'Create Time',
      'The date when the number was set to In Service in Catapult',
    ],
    [
      'Release Time',
      'The date when the number was Released from service in Catapult',
    ],
  ];

  return <ToolTipTable data={tooltip} />;
};
