import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  colors,
  AppBar,
  Toolbar,
  Logo,
  Typography,
  makeStyles,
  Box,
} from '@bw/foundry';
import LogoutLink from './LogoutLink';

export interface NavigationProps {
  isSnapbackUser?: boolean;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(22),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    // Overpass... sigh
    position: 'relative',
    top: -2,
  },
  logo: {
    // adjusted due to built-in logo space
    marginRight: theme.spacing(0.5),
  },
  logoDivider: {
    color: 'inherit',
    borderLeft: `1px solid ${theme.palette.secondary.contrastText}`,
    marginRight: theme.spacing(1),
    width: 1,
    height: 32,
  },
  rightSection: {
    marginLeft: 'auto',
    // pushes this section down to the bottom of the bar
    alignSelf: 'flex-end',
    display: 'flex',
    // Causes buttons to appear side-by-side
    gap: '20px',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(1),
  },
  defaultBtn: {
    color: 'white',
    textDecoration: 'none',
    fontSize: 14,
  },
  activeBtn: {
    color: colors.blue[500],
    textDecoration: 'none',
    fontSize: 14,
  },
}));

/** Basic navigation component that shows links in the nav bar. */
const Navigation: React.FC<NavigationProps> = ({ isSnapbackUser }) => {
  const classes = useStyles();

  // Only allow the user to view the snapback page if they have permission
  if (isSnapbackUser) {
    return (
      <AppBar color="primary">
        <Toolbar>
          <Logo size="large" color="white" className={classes.logo} />
          <div className={classes.logoDivider} />
          <Typography variant="h1" color="inherit" className={classes.title}>
            Numbers History Report
          </Typography>
        </Toolbar>
        <Box className={classes.rightSection}>
          <NavLink
            className={({ isActive }) =>
              classes.defaultBtn + (isActive ? ' ' + classes.activeBtn : '')
            }
            end
            to="/"
            data-testid="NhaNavigator"
          >
            Numbers History Report
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              classes.defaultBtn + (isActive ? ' ' + classes.activeBtn : '')
            }
            to="/snapback-details"
            data-testid="SnapbackNavigator"
          >
            Snapback Details
          </NavLink>
          <LogoutLink />
        </Box>
      </AppBar>
    );
  }

  return (
    <AppBar color="primary">
      <Toolbar>
        <Logo size="large" color="white" className={classes.logo} />
        <div className={classes.logoDivider} />
        <Typography variant="h1" color="inherit" className={classes.title}>
          Numbers History Report
        </Typography>
      </Toolbar>
      <Box className={classes.rightSection}>
        <LogoutLink />
      </Box>
    </AppBar>
  );
};

export default Navigation;
