import { useOktaAuth } from '@okta/okta-react';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

/**
 * Passes user and account information to third-party services. Needs
 * to be lower in the tree than the Okta provider
 */
// eslint-disable-next-line
const ThirdParty = () => {
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      return;
    }

    (async () => {
      const oktaUser = await oktaAuth.getUser();
      datadogRum.setUser({
        id: oktaUser.preferred_username,
        email: oktaUser.email,
      });

      // Uncomment this line to enable session replay
      // https://docs.datadoghq.com/real_user_monitoring/session_replay/
      // datadogRum.startSessionReplayRecording();
    })();
  }, [authState, oktaAuth]);

  return null;
};

export default ThirdParty;
