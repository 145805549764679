import appConfig from './appConfig.json';
import { matchesEnvironment } from '../utils/matchEnvironment';

const pickConfig = (): string => {
  // uat environment uses the `test` subdomain
  if (matchesEnvironment('test')) {
    // @ts-ignore
    return appConfig.numbersReadSvc.uat.url;
  }

  // all these 3 environments share the `eng` subdomain
  if (matchesEnvironment('pr')) {
    // @ts-ignore
    return appConfig.numbersReadSvc.pr.url;
  }

  if (matchesEnvironment('ci')) {
    // @ts-ignore
    return appConfig.numbersReadSvc.ci.url;
  }

  if (matchesEnvironment('eng')) {
    // @ts-ignore
    return appConfig.numbersReadSvc.stage.url;
  }
  if (matchesEnvironment('local')) {
    // @ts-ignore
    return appConfig.numbersReadSvc.stage.url;
  }

  // dev environment uses the `dev` subdomain
  if (matchesEnvironment('dev')) {
    // @ts-ignore
    return appConfig.numbersReadSvc.dev.url;
  }

  // default to Prod
  // @ts-ignore
  return appConfig.numbersReadSvc.prod.url;
};

const serviceConfig = pickConfig();
export default serviceConfig;
