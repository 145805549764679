import { HookshotError, useWrite, UseWriteOptions } from '@bw/hookshot';
import {
  PostNumberHistoryReport,
  PostNumberHistoryReportResponse,
} from '../types/PostNumberHistoryReportResponse';
import { LogEvent, logEvent, LogEventStatus } from '../utils/logEvent';
import _ from 'lodash';
import { NumberHistoryReportRequest } from '../types/NumberHIstoryReportRequest';

const NUMBERS_REPORT_URL = '/api/reporting/v1/numberHistoryReports';

const NUMBERS_QUERY_ORDER_STATUS = '?fields=*';

type NumberHistoryReportVars = {
  input: NumberHistoryReportRequest;
};

const transformRequest = (data: NumberHistoryReportRequest) => {
  return data;
};

const transformResponse = (
  wrappedData: PostNumberHistoryReportResponse,
): PostNumberHistoryReportResponse => {
  if (!wrappedData) {
    return wrappedData;
  }
  const ret = wrappedData;
  ret.data = wrappedData.data.map((i) => {
    return reorder(i);
  });
  return ret;
};

const reorder = (data: PostNumberHistoryReport) => {
  const ret = _.clone(data);
  ret.irisTnHistory?.sort((a, b) => {
    return -1 * a.lastModifiedDate.localeCompare(b.lastModifiedDate);
  });
  return ret;
};

const transformError = (error: HookshotError): HookshotError => {
  if (!error) {
    return error;
  }
  logEvent(LogEvent.APIResponse, LogEventStatus.HandledError, {
    error: error.data[0],
  });
  return error.data[0];
};
const useNumberHistoryReports = (
  fetchOrderStatus?: boolean,
  options?: UseWriteOptions<PostNumberHistoryReportResponse>,
): any => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useWrite<NumberHistoryReportVars, PostNumberHistoryReportResponse>(
    fetchOrderStatus
      ? NUMBERS_REPORT_URL + NUMBERS_QUERY_ORDER_STATUS
      : NUMBERS_REPORT_URL,
    'POST',
    {
      transformRequest,
      transformResponse,
      transformError,
      ...options,
    },
  );
};

export default useNumberHistoryReports;
