import { OktaAuthOptions } from '@okta/okta-auth-js';
import appConfig from './appConfig.json';
import { matchesEnvironment } from '../utils/matchEnvironment';

const baseOktaConfig: OktaAuthOptions = {
  redirectUri: '/okta/callback',
  postLogoutRedirectUri: '/loggedOut',
  scopes: ['openid', 'oneid', 'profile', 'email'],
  pkce: true,
  devMode: process.env.NODE_ENV === 'development',
  cookies: {
    secure: true,
  },
  storageManager: {
    token: {
      storageTypes: ['sessionStorage'],
    },
  },
  transformAuthState: async (oa, authState) => {
    if (!authState?.isAuthenticated) {
      return authState;
    }
    const sessionExists = await oa?.session?.exists();
    if (!sessionExists) {
      oa?.tokenManager?.clear();
    }

    return {
      ...authState,
      isAuthenticated: authState.isAuthenticated && sessionExists,
    };
  },
};

const pickConfig = (): OktaAuthOptions & { url: string } => {
  // uat environment uses the `test` subdomain
  if (matchesEnvironment('test')) {
    return {
      url: 'https://uat.passport.bandwidth.com',
      ...appConfig?.passport?.uat,
      ...baseOktaConfig,
    };
  }

  // all these 3 environments share the `eng` subdomain
  if (matchesEnvironment('pr')) {
    return {
      url: 'https://stage.passport.bandwidth.com',
      ...appConfig?.passport?.stage,
      ...baseOktaConfig,
    };
  }

  if (matchesEnvironment('ci')) {
    return {
      url: 'https://stage.passport.bandwidth.com',
      ...appConfig?.passport?.stage,
      ...baseOktaConfig,
    };
  }

  if (matchesEnvironment('eng')) {
    return {
      url: 'https://stage.passport.bandwidth.com',
      ...appConfig?.passport?.stage,
      ...baseOktaConfig,
    };
  }

  if (matchesEnvironment('local')) {
    return {
      url: 'https://stage.passport.bandwidth.com',
      ...appConfig?.passport?.stage,
      ...baseOktaConfig,
    };
  }

  // dev environment uses the `dev` subdomain
  if (matchesEnvironment('dev')) {
    return {
      url: 'https://dev.passport.bandwidth.com',
      ...appConfig?.passport?.dev,
      ...baseOktaConfig,
    };
  }

  // default to prod
  return {
    url: 'https://passport.bandwidth.com',
    ...appConfig?.passport?.prod,
    ...baseOktaConfig,
  };
};

const oktaConfig = pickConfig();
export default oktaConfig;
