import React, { PropsWithChildren } from 'react';
import { Security } from '@okta/okta-react';
import { OAuthError, OktaAuth } from '@okta/okta-auth-js';
import urlcat from 'urlcat';
import oktaConfig from './config/oktaConfig';
import { LogEvent, LogEventStatus, logEvent } from 'utils/logEvent';

export const oktaAuth = new OktaAuth(oktaConfig);

export const OktaAuthSecurity: React.FC<PropsWithChildren> = ({ children }) => {
  const customAuthHandler = async (oa: OktaAuth) => {
    return oa.token
      .getWithoutPrompt({
        responseType: ['id_token', 'token'],
      })
      .then((res) => {
        const { tokens } = res;
        oa.removeOriginalUri();
        oa.tokenManager.setTokens(tokens);
        oa.authStateManager.updateAuthState();
      })
      .catch((err: any) => {
        if (!(err instanceof OAuthError)) {
          logEvent(LogEvent.ClickLink, LogEventStatus.UnhandledError, {
            message: 'unknown auth error',
            error: err,
          });
        } else if (err.errorCode === 'login_required') {
          window.location.href = urlcat(oktaConfig.url, 'login', {
            returnTo: window.location.href,
          });
        } else if (err.errorCode === 'access_denied') {
          oa.revokeAccessToken();
          oa.revokeRefreshToken();
          oa.closeSession();
          window.location.href = urlcat(oktaConfig.url, 'login', {
            alert: 'unassignedApplication',
            returnTo: window.location.href,
          });
        }
      });
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={() => {}}
    >
      {children}
    </Security>
  );
};
