import React, { ReactElement, useCallback, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  HelpIcon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@bw/foundry';
import NavLinkItem from './NavLinkItem';

export interface DataTableProps {
  cs?: string[];
  data?: any[][];
  title?: string;
  urlTitle?: string;
  limit?: number;
  tn: string;
  toolTip?: ReactElement;
  hasError?: boolean;
  serviceTimeout?: boolean;
  serviceName?: string;
}

export const DataTable: React.FC<DataTableProps> = ({
  cs,
  data,
  title,
  limit,
  urlTitle,
  tn,
  toolTip,
  hasError,
  serviceTimeout,
  serviceName,
}) => {
  const columns = cs;
  const d = data;
  const [showSmall, setShowSmall] = useState(false);
  const toggleSmall = useCallback(
    () => setShowSmall(!showSmall),
    [showSmall, setShowSmall],
  );

  const [overflow, setOverflow] = useState(false);

  const Body = () => {
    let size;
    if (limit && d && limit > d.length) {
      size = d.length;
      setOverflow(false);
    } else {
      size = limit;
      setOverflow(true);
    }
    return (
      <TableBody>
        {d
          ?.slice(0, size)
          .map((row) => (
            <TableRow key={Math.random()}>
              {row.map((r) => getBody(r))}
            </TableRow>
          ))}
      </TableBody>
    );
  };

  const getBody = (r: any) => {
    return <TableCell key={r}>{r}</TableCell>;
  };

  const OpenModal = () => {
    return (
      <Dialog open={showSmall} onClose={toggleSmall} maxWidth="lg">
        <DialogTitle onClose={toggleSmall}>{title}</DialogTitle>
        <DialogContent>{toolTip}</DialogContent>
      </Dialog>
    );
  };

  const Title = () => {
    const ref = `details/${urlTitle}/${tn}`;
    if (overflow) {
      return (
        <>
          <Typography variant="h4" gutterBottom>
            {title}
            <IconButton onClick={toggleSmall}>
              <HelpIcon />
            </IconButton>
            <NavLinkItem to={ref} style={{ paddingLeft: 10 }} exact>
              View All
            </NavLinkItem>
          </Typography>
          <OpenModal />
        </>
      );
    }
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {title}
          <IconButton onClick={toggleSmall}>
            <HelpIcon />
          </IconButton>
        </Typography>

        <OpenModal />
      </>
    );
  };
  if (hasError) {
    return (
      <Paper>
        <div style={{ padding: 20 }}>
          <Title />
          <Table size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: '#FFEDE8' }}>
                <TableCell style={{ color: '#A53F0C' }}>
                  Error - Unexpected results or unable to reach {serviceName}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </div>
      </Paper>
    );
  }
  if (serviceTimeout) {
    return (
      <Table size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: '#FFEDE8' }}>
            <TableCell style={{ color: '#A53F0C' }}>
              Could not fetch response in time. Please try again later.
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    );
  }
  if (Array.isArray(data) && data[0]?.length) {
    return (
      <Paper>
        <Box p={2}>
          <Title />
          <Table size="medium">
            <TableHead>
              <TableRow>
                {columns?.map((c) => (
                  <TableCell style={{ fontWeight: 'bold' }} key={c}>
                    {c}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <Body />
          </Table>
        </Box>
      </Paper>
    );
  }
  return (
    <Paper>
      <div style={{ padding: 20 }}>
        <Title />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>No results found</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </div>
    </Paper>
  );
};
