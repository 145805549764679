import { matchesEnvironment } from '../utils/matchEnvironment';
import appConfig from './appConfig.json';

const pickConfig = () => {
  const env = getEnvironment();
  return {
    site: 'datadoghq.com',
    service: 'numbers-history-app',
    version: '0.1',
    sampleRate: 100,
    env,
    ...(['prod', 'uat', 'test'].includes(env)
      ? appConfig.datadog.prod
      : appConfig.datadog.nonProd),
  };
};

const getEnvironment = () => {
  if (matchesEnvironment('local')) {
    return 'local';
  }
  if (matchesEnvironment('pr')) {
    return 'pr';
  }
  if (matchesEnvironment('test')) {
    return 'uat';
  }
  if (matchesEnvironment('ci')) {
    return 'ci';
  }
  if (matchesEnvironment('dev')) {
    return 'dev';
  }

  return 'prod';
};

const datadogConfig = pickConfig();
export default datadogConfig;
