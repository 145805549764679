import { CatapultHistory } from '../CatapultHistory';
import { DataTable } from '../../features/DataTable';
import { ToolTipCatapult } from '../toolTip/ToolTipCatapult';
import React from 'react';
import { DetailedDataTable } from '../../features/DetailedDataTable';

export interface CatapultColumnProps {
  catapultHistory: CatapultHistory[];
  tn: string;
  hasError?: boolean;
  detailedTable?: boolean;
}

export const CatapultColumn: React.FC<CatapultColumnProps> = ({
  catapultHistory,
  tn,
  hasError,
  detailedTable,
}) => {
  const c: string[][] = [[]];

  catapultHistory?.forEach((i, idx) => {
    c[idx] = [];
    c[idx].push(i.number);
    c[idx].push(i.numberId);
    c[idx].push(i.firstName);
    c[idx].push(i.lastName);
    c[idx].push(i.companyName);
    c[idx].push(JSON.stringify(i.numberState));
    c[idx].push(i.createdTime);
    c[idx].push(i.releasedTime);
  });
  if (detailedTable) {
    return (
      <DetailedDataTable
        cs={CatapultColumns}
        data={c}
        title={TableNameCatapult}
        tn={tn}
      />
    );
  }

  return (
    <DataTable
      cs={CatapultColumns}
      data={c}
      title={TableNameCatapult}
      limit={10}
      urlTitle={TableUrlNameCatapult}
      tn={tn}
      toolTip={<ToolTipCatapult />}
      serviceName="Catapult DB"
      hasError={hasError}
    />
  );
};
export const CatapultColumns = [
  'Number',
  'Number Id',
  'First Name',
  'Last Name',
  'Company Name',
  'Number State',
  'Create Time',
  'Release Time',
];

export const TableNameCatapult = 'Catapult';

export const TableUrlNameCatapult = 'catapult';
