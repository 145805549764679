import React from 'react';
import { ToolTipTable } from '../../features/ToolTipTable';

export const ToolTipMyBwc: React.FC = () => {
  const telephoneNumber = () => (
    <div>
      The type of telephone number
      <ul>
        <li>LI -Local Inbound</li>
        <li>ELS - Enhanced Local Service</li>
        <li>Toll-free - 800, 888, 877, 866, 855, and 844</li>
      </ul>
    </div>
  );
  const status = () => {
    return (
      <div>
        <ul>
          <li>
            Aging - The number is in Aging status before moving to Available
          </li>
          <li>Available - The number is available for order</li>
          <li>
            Cancelled - The number was pending a Port In but was cancelled prior
            to completion
          </li>
          <li>Disconnected - The number is no longer in service </li>
          <li>
            EOL - End of Life. This indicates the number is in a Rate Center
            where we have no coverage
          </li>
          <li>InService - The number is in Service and active</li>
          <li>Ordered - The number has been ordered</li>
          <li>
            PendingBandwidth - The number is awaiting an action from MyBwc
          </li>
          <li>PendingVendor - The number is awaiting an action from Neustar</li>
          <li>
            PortOut - The number has been ported out of MyBwc and is no longer
            in MyBwc
          </li>
          <li>Provisioning - The number is currently provisioning</li>
          <li>
            Requisitioned - The number has been ordered from another carrier
            before moving to Available status
          </li>
        </ul>
      </div>
    );
  };
  const tooltip = [
    ['Full Number', 'Telephone numbers searched for'],
    ['TN Type', telephoneNumber()],
    [
      'Account Name',
      'The account this telephone number is currently associated with',
    ],
    ['Status', status()],
    ['History Date Created', 'Date of record change'],
    [
      'Primary Email',
      'Email address associated with the user that updated this record',
    ],
    ['Name', 'User name that updated this record'],
    [
      'Obsolete',
      'Obsolete flag. This indicates the telephone number is no longer present on the system',
    ],
    ['Gateway Name', 'Current gateway this telephone number is provisioned to'],
    ['Vendor Name', 'Vendor this telephone number is associated with'],
  ];

  return <ToolTipTable data={tooltip} />;
};
